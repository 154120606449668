import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { booksContext } from "../../context/books-provider/BooksProvider";
import { collectionsContext } from "./../../context/collections-provider/CollectionsProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";

// types
import type { BookWithQuantity } from "../../context/books-provider/BooksProvider.types";
import type { CollectionType } from "../../context/collections-provider/CollectionsProvider.types";

export function usePreviewModalFetch(isOpen: boolean) {
  const { error } = useContext(errorContext);
  const { books, getBooks } = useContext(booksContext);
  const { collectionsData, getCollections } = useContext(collectionsContext);
  const { cartProducts, getCartProducts } = useContext(cartContext);

  const [isCartLoading, setIsCartLoading] = useState(false);

  const [filteredCart, setFilteredCart] = useState<{
    books: BookWithQuantity[];
    collections: CollectionType[];
  } | null>(null);

  const [totalPrice, setTotalPrice] = useState("");

  useEffect(() => {
    if (books && collectionsData) {
      const bookCountMap = cartProducts.books.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);

      const filteredBooks = books
        .filter(({ id }) => bookCountMap[id])
        .map((book) => ({
          ...book,
          quantity: bookCountMap[book.id],
        }));

      const filteredCollections = collectionsData.filter((collection) =>
        cartProducts.collections.includes(collection.id)
      );

      setFilteredCart({
        books: filteredBooks,
        collections: filteredCollections,
      });

      const booksTotalWithDiscountPrice = filteredBooks.map(
        (filteredCartBook) => {
          const price = filteredCartBook.discountPrice
            ? Number(filteredCartBook.discountPrice)
            : Number(filteredCartBook.price);
          return price * filteredCartBook.quantity;
        }
      );

      const booksTotal = booksTotalWithDiscountPrice.length
        ? booksTotalWithDiscountPrice
            .reduce((acc, currValue) => acc + currValue, 0)
            .toFixed(2)
        : "0";

      const collectionsTotalWithDisountPrice = filteredCollections.map(
        (cartProduct) => {
          return Number(cartProduct.price);
        }
      );

      const collectionsTotal = collectionsTotalWithDisountPrice.length
        ? collectionsTotalWithDisountPrice
            .reduce((acc, currValue) => acc + currValue)
            .toFixed(2)
        : 0;

      const total = (Number(booksTotal) + Number(collectionsTotal)).toFixed(2);

      setTotalPrice(String(total));
    }
  }, [books, collectionsData, cartProducts]);

  const cartFetch = async () => {
    try {
      if (isOpen) {
        setIsCartLoading(true);

        getCartProducts();

        await getBooks();

        await getCollections();
      }
    } catch (err) {
      error(err);
    } finally {
      setIsCartLoading(false);
    }
  };

  useEffect(() => {
    cartFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    isCartLoading,
    filteredCart,
    totalPrice,
  };
}
