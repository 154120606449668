import { SVGProps } from "react";

const OpenEyeIcon = ({
  className,
  color = "#FDFDFD",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6C8.16094 6 5.17031 8.2875 1.5 12C4.65938 15.1734 7.3125 18 12 18C16.6828 18 20.1281 14.4187 22.5 12.0656C20.0719 9.30937 16.6313 6 12 6ZM12 16.2797C9.68437 16.2797 7.8 14.3578 7.8 12C7.8 9.6375 9.68437 7.72031 12 7.72031C14.3156 7.72031 16.2 9.64219 16.2 12C16.2 14.3625 14.3156 16.2797 12 16.2797Z"
        fill={color}
      />
      <path
        d="M11.9984 10.4999C11.9984 10.1296 12.1344 9.79209 12.3547 9.52959C12.2375 9.51084 12.1203 9.50146 11.9984 9.50146C10.6484 9.50146 9.54688 10.6218 9.54688 11.9999C9.54688 13.378 10.6484 14.4983 11.9984 14.4983C13.3484 14.4983 14.45 13.378 14.45 11.9999C14.45 11.8921 14.4406 11.7843 14.4313 11.6765C14.1734 11.878 13.8547 11.9999 13.5031 11.9999C12.6688 11.9999 11.9984 11.3296 11.9984 10.4999Z"
        fill={color}
      />
    </svg>
  );
};
export { OpenEyeIcon };
