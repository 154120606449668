import { useContext, useEffect } from "react";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// icons
import { CloseIcon } from "../../../assets/icons/CloseIcon";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./CartModalBook.module.scss";

//translations
import { Trans } from "@lingui/macro";

type CartModalBookProps = {
  cartProduct: BookType;
};

export function CartModalBook({ cartProduct }: CartModalBookProps) {
  const {
    cartProducts,
    getCartProducts,
    addBookToCart,
    removeOneBook,
    deleteBookFromCart,
  } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const booksQuantity = cartProducts.books.filter(
    (book) => book === cartProduct.id
  ).length;

  const handleAddOneMoreBook = () => {
    addBookToCart(cartProduct);
  };

  const handleRemoveOneBook = () => {
    removeOneBook(cartProduct.id);
  };

  useEffect(() => {
    getCartProducts();
  }, [booksQuantity]);

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={getImageUrl(cartProduct.picture)} alt={cartProduct.title} />
      </div>
      <div className={styles.infoWrapper}>
        <h6>{cartProduct.title}</h6>
        <p>{cartProduct.description}</p>
      </div>
      <div className={styles.priceAndQuantityWrapper}>
        <div className={styles.pricesWrapper}>
          {cartProduct.discountPrice ? (
            <p>
              {getCurrencySymbol()}
              {cartProduct.discountPrice}
            </p>
          ) : null}
          <div className={cartProduct.discountPrice ? styles.diagonalLine : ""}>
            <p className={cartProduct.discountPrice ? styles.oldPrice : ""}>
              {getCurrencySymbol()}
              {cartProduct.price}
            </p>
          </div>
        </div>
        <div className={styles.quantityWrapper}>
          <p className={styles.quantityText}>
            <Trans>Quantity</Trans>
          </p>
          <div className={styles.counterWrapper}>
            <div className={styles.quantity}>{booksQuantity}</div>
            <div className={styles.marksWrapper}>
              <div className={styles.plus} onClick={handleAddOneMoreBook}>
                +
              </div>
              <div className={styles.minus} onClick={handleRemoveOneBook}>
                -
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteBookFromCart(cartProduct.id)}
        >
          <CloseIcon color="#A21916" width={13} height={13} />
          <Trans>Remove</Trans>
        </div>
      </div>
    </div>
  );
}
