import { z } from "zod";

export const countrySchema = z.object({
  name: z.string(),
  short_code: z.string(),
});

export const allCountriesSchema = z.array(countrySchema);

const mediaSchema = z.object({
  logo: z.string().url(),
});

export const bankServiceSchema = z.object({
  code: z.string().uuid(),
  name: z.string(),
  media: mediaSchema,
});

export const allBanksSchema = z.record(z.string(), z.array(bankServiceSchema));

const browserSchema = z.object({
  acceptHeader: z.string(),
  colorDepth: z.number().int().min(1),
  javaEnabled: z.boolean(),
  language: z.string().min(2),
  screenHeight: z.number().int().positive(),
  screenWidth: z.number().int().positive(),
  timeZoneOffset: z.number().int(),
  userAgent: z.string(),
  windowHeight: z.number().int().positive(),
  windowWidth: z.number().int().positive(),
});

const clientSchema = z.object({
  email: z.string().email(),
  phone: z.string(),
});

const payerDataSchema = z.object({
  bankId: z.string(),
  payerAccountNumber: z.string().optional(),
  payerIban: z.string().optional(),
  payerSortCode: z.string().optional(),
  paymentCountry: z.string().optional(),
});

export const transactionFormSchema = z.object({
  amount: z.number().min(0),
  browser: browserSchema,
  client: clientSchema,
  currencyCode: z.string(),
  locale: z.string(),
  orderId: z.string(),
  payerData: payerDataSchema,
});

export const transactionSchema = z.object({
  acsUrl: z.string(),
});

export const secondTransactionSchema = z.object({
  createdAt: z.string(),
  id: z.string(),
  orderId: z.string(),
  status: z.string(),
});

export const checkTransactionSchema = z.object({
  url: z.string(),
});
