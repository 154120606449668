import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// tranlsations
import { Trans } from "@lingui/macro";

// consts
import { PATHS } from "../../../../route/route.controls";
import {
  COMPANY_NAME,
  ACCOUNT_NUMBER,
  SORT_CODE,
  IBAN,
} from "../../../../config";

// contexts
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../../../components/button/Button";

// types
import type {
  BankType,
  TransactionType,
} from "../../../../context/open-banking-provider/OpenBankingProvider.types";

// styles
import styles from "./ConfirmStep.module.scss";

type ConfirmStepProps = {
  bank: BankType & { country: string };
  amount: string;
  transactionData: TransactionType | null;
};

export function ConfirmStep({
  bank,
  amount,
  transactionData,
}: ConfirmStepProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h3>
        <Trans>
          We have partnered with Yapily Connect to securely initiate payment
          from your account at
        </Trans>{" "}
        {bank.name}.
      </h3>
      <div>
        <p className={styles.text}>
          <Trans>Reference:</Trans> {transactionData?.id}
        </p>
      </div>
      <div>
        <p className={styles.text}>
          <Trans>Payment Total:</Trans>{" "}
          <span>
            {amount} {getCurrencySymbol()}
          </span>
        </p>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.title}>
          <Trans>Payee Details</Trans>
        </p>
        <ul className={styles.listWrapper}>
          <li className={styles.listItem}>
            <p className={styles.text}>
              <Trans>Payee Name:</Trans> {COMPANY_NAME}
            </p>
          </li>
          <li className={styles.listItem}>
            <p className={styles.text}>
              <Trans>Payee Account Identification:</Trans>

              {bank.country === "GB" ? (
                <>
                  <p className={styles.text}>
                    <Trans>Account Number:</Trans> {ACCOUNT_NUMBER}
                  </p>
                  <p className={styles.text}>
                    <Trans>Sort code:</Trans> {SORT_CODE}
                  </p>
                </>
              ) : (
                <p className={styles.text}>
                  <Trans>IBAN:</Trans> {IBAN}
                </p>
              )}
            </p>{" "}
          </li>
        </ul>
      </div>

      <p className={styles.text}>
        <Trans>
          By using the service, you agree to Yapily Connect initiating this
          payment and its
        </Trans>{" "}
        <a
          className={styles.link}
          href="https://www.yapily.com/legal/end-user-terms?_gl=1*1n6ibnz*_gcl_au*NzEyOTg5MjQ3LjE3MzA3OTkwNzY"
          target="_blank"
        >
          <Trans>Terms & Conditions</Trans>
        </a>{" "}
        <Trans>and</Trans>{" "}
        <a
          className={styles.link}
          href="https://www.yapily.com/legal/privacy-policy?_gl=1*1n6ibnz*_gcl_au*NzEyOTg5MjQ3LjE3MzA3OTkwNzY"
          target="_blank"
        >
          <Trans>Privacy Notice</Trans>
        </a>
        .
      </p>
      <div className={styles.buttonsWrapper}>
        <Button
          variant="outline"
          className={styles.cancelButton}
          onClick={() => navigate(PATHS.index)}
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button
          onClick={() => (window.location.href = transactionData?.acsUrl || "")}
        >
          <Trans>Confirm</Trans>
        </Button>
      </div>
    </div>
  );
}
