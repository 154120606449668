import { useContext } from "react";

// contexts
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";
import { cartContext } from "../../../context/cart-provider/CartProvider";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./PreviewCartBook.module.scss";

//translation
import { Trans } from "@lingui/macro";

type PreviewCartBookProps = {
  book: BookType;
};

export function PreviewCartBook({ book }: PreviewCartBookProps) {
  const { getCurrencySymbol } = useContext(currencyContext);
  const { cartProducts } = useContext(cartContext);

  const booksQuantity = cartProducts.books.filter(
    (cartBook) => cartBook === book.id,
  ).length;

  return (
    <div className={styles.book} key={book.id}>
      <div className={styles.bookInfo}>
        <>
          <img src={getImageUrl(book.picture)} alt={`${book.title}`} />
          <div>
            <p>{book.title}</p>
            <p className={styles.quantity}>
              <Trans>Quantity</Trans>: {booksQuantity}
            </p>
          </div>
        </>
      </div>

      <div className={styles.pricesWrapper}>
        <>
          {book.discountPrice ? (
            <p>
              {getCurrencySymbol()}
              {book.discountPrice}
            </p>
          ) : null}
          <div className={book.discountPrice ? styles.diagonalLine : ""}>
            <p className={styles.oldPrice}>
              {getCurrencySymbol()}
              {book.price}
            </p>
          </div>
        </>
      </div>
    </div>
  );
}
