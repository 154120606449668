import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// translation
import { t, Trans } from "@lingui/macro";

// contexts
import { localesContext } from "../../../context/local-provider/LocalProvider";

// consts
import { PATHS } from "../../../route/route.controls";

// hooks
import { useOpenBankingFetch, useOpenBankingForm } from "./use-open-banking";

// components
import Spinner from "../../../components/spinner/Spinner";
import { BankSelecting } from "./bank-selecting/BankSelecting";
import { PayerAccount } from "./payer-account/PayerAccount";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";

// styles
import styles from "./OpenBanking.screen.module.scss";
import { ConfirmStep } from "./confirm-step/ConfirmStep";

export function OpenBankingScreen() {
  const { i18n } = useContext(localesContext);

  const { isOpenBankingLoading } = useOpenBankingFetch();

  const {
    isCartLoading,
    totalPrice,
    selectedBank,
    confirmPage,
    payerFormData,
    isDisabledButton,
    setSelectedBank,
    setConfirmPage,
    handleChangePayerData,
    clearPayerData,
    onSubmit,
  } = useOpenBankingForm();

  const [isOpenClosingModal, setIsOpenClosingModal] = useState(false);

  const navigate = useNavigate();

  const isFirstStep = selectedBank === null;
  const paymentStep = !!selectedBank && !confirmPage;
  const confirmStep = !!selectedBank && confirmPage;

  const isLoading = isCartLoading || isOpenBankingLoading;

  return (
    <section className={styles.openBankingSection}>
      <div className={styles.contentWrapper}>
        {isLoading ? (
          <div className={styles.openBankingLoader}>
            <Spinner size="large" />
          </div>
        ) : (
          <>
            {isFirstStep ? (
              <BankSelecting
                setSelectedBank={setSelectedBank}
                setIsOpenClosingModal={setIsOpenClosingModal}
                totalPrice={totalPrice}
              />
            ) : null}

            {paymentStep ? (
              <PayerAccount
                selectedBank={selectedBank}
                payerFormData={payerFormData}
                setSelectedBank={setSelectedBank}
                setConfirmPage={setConfirmPage}
                setIsOpenClosingModal={setIsOpenClosingModal}
                isDisabledButton={isDisabledButton}
                handleChangePayerData={handleChangePayerData}
                clearPayerData={clearPayerData}
              />
            ) : null}

            {confirmStep ? (
              <ConfirmStep
                bank={selectedBank}
                amount={totalPrice}
                payerFormData={payerFormData}
                onSubmit={onSubmit}
              />
            ) : null}
          </>
        )}
      </div>

      <ConfirmModal
        isOpen={isOpenClosingModal}
        modalTitle={"Close checkout"}
        modalText={
          <Trans>
            Are you sure you want to quit the checkout process? <br />
            Your order will not be completed.
          </Trans>
        }
        applyButtonText={t(i18n)`Yes, quit`}
        declinedButtonText={t(i18n)`No, continue`}
        onClose={() => setIsOpenClosingModal(false)}
        onSubmit={() => navigate(PATHS.index)}
      />
    </section>
  );
}
