// types
import type { BankType } from "../../../../../context/open-banking-provider/OpenBankingProvider.types";

// styles
import styles from "./BankItem.module.scss";

type BankItemProps = {
  bank: BankType & { country: string };
  setSelectedBank: React.Dispatch<
    React.SetStateAction<(BankType & { country: string }) | null>
  >;
};

export function BankItem({ bank, setSelectedBank }: BankItemProps) {
  return (
    <div className={styles.container} onClick={() => setSelectedBank(bank)}>
      {"logo" in bank.media ? (
        <img src={bank.media.logo} alt={bank.name} />
      ) : null}

      <p className={styles.title}>{bank.name}</p>
    </div>
  );
}
