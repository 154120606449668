import { useContext, useRef, useState } from "react";
import classnames from "classnames";
import { Link, generatePath, useNavigate } from "react-router-dom";

// translations
import { languages } from "../../context/local-provider/LocaleProvider.const";
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";
import { LanguageSelector } from "../language-selector/LanguageSelector";

// context
import { sessionContext } from "../../context/session-provider/SessionProvider";
import { userContext } from "../../context/user-provider/UserProvider";
import { booksContext } from "../../context/books-provider/BooksProvider";
import { collectionsContext } from "../../context/collections-provider/CollectionsProvider";
import { localesContext } from "../../context/local-provider/LocalProvider";

// images
import user from "../../assets/images/user.png";

// helpers
import { truncateString } from "../../helpers/truncate-string";

// hooks
import { useHeaderFetch, useHeaderForm } from "./use-header";
import { useAuthForm } from "../../side/guest/auth-screen/use-auth-form";
import { useClickOutside } from "../../hooks/use-click-outside/use-click-outside";
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";
import useLockBodyScroll from "../../hooks/use-lock-body-scroll/use-lock-body-scroll";

// consts
import { PATHS } from "../../route/route.controls";
import { useHeaderConsts } from "./use-header-consts";

// icons
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";
import { CartIcon } from "../../assets/icons/CartIcon";
import { LogoIcon } from "../../assets/icons/LogoIcon";

// components
import Input from "../input/Input";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import { PreviewCartModal } from "../preview-cart-modal/PreviewCartModal";
import { CartModal } from "../cart-modal/CartModal";
import { CurrencySelector } from "../currency-selector/CurrencySelector";

// styles
import styles from "./Header.module.scss";

type HeaderProps = {
  isAdminRoutes?: boolean;
};

export function Header({ isAdminRoutes }: HeaderProps) {
  const { locale, i18n, changeLanguage } = useContext(localesContext);
  const handleChangeLanguge = ({ value }: any) => {
    changeLanguage(value);
  };
  const { userData } = useContext(userContext);
  const { getRoleFromToken } = useContext(sessionContext);
  const { filteredBooks } = useContext(booksContext);
  const { filteredCollections } = useContext(collectionsContext);

  const { navigationLinks, adminLinksData, userLinksData } = useHeaderConsts();

  const { width } = useWindowSize();

  const {
    bookTitle,
    currencyData,
    currencyOptions,
    handleChangeSelectData,
    handleChangeHeaderData,
    clearFilteredProducts,
  } = useHeaderForm();

  const { handleLogOut } = useAuthForm();

  const {
    isAuthLoading,
    isOpenPreviewCart,
    isOpenCartModal,
    cartCount,
    handleOpenCartModal,
    setIsOpenPreviewCart,
    setIsOpenCartModal,
  } = useHeaderFetch();

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocusedMenu, setIsFocusedMenu] = useState(false);

  const handleNavigateToCurrentBook = (bookId: string) => {
    const generatedLink = generatePath(PATHS.book, {
      bookId,
    });
    clearFilteredProducts();

    navigate(generatedLink);
  };

  const handleNavigateToCurrentCollection = (collectionId: string) => {
    const generatedLink = generatePath(PATHS.collection, {
      collectionId,
    });

    clearFilteredProducts();

    navigate(generatedLink);
  };

  const clickRef = useRef(null);
  const inputWrapperRef = useRef<null>(null);

  useLockBodyScroll(isOpen);

  useClickOutside(clickRef, () => setShow(false));
  useClickOutside(inputWrapperRef, () => setIsFocusedMenu(false));

  const role = getRoleFromToken();

  const isAdminRole = role === "admin";

  const linksData = isAdminRole ? adminLinksData : userLinksData;

  const isAuthenticated = !!role;

  return (
    <header
      className={classnames(
        styles.header,
        isAdminRoutes ? styles.adminHeader : styles.clientHeader
      )}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.mainWrapper}>
          <div className={styles.logo}>
            <LogoIcon onClick={() => navigate(PATHS.index)} />
          </div>

          <div
            className={classnames(
              styles.cartIcon,
              styles.mobileCartIcon,
              isOpen ? styles.isOpenedCart : ""
            )}
            onClick={() => setIsOpenPreviewCart(true)}
          >
            {cartCount ? (
              <div className={styles.cartAmount}>
                <p>{cartCount}</p>
              </div>
            ) : null}

            <CartIcon />
          </div>

          <input
            id="menu__toggle"
            type="checkbox"
            className={styles.menuToggle}
            checked={isOpen}
            onChange={(e) => setIsOpen(!e.target.checked)}
          />

          <label
            className={styles.menuBtn}
            htmlFor="menu__toggle"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <span></span>
          </label>

          <div className={styles.linksWrapper}>
            <div className={styles.navigation}>
              {navigationLinks.map((link, index) => (
                <Link
                  to={link.to}
                  key={index}
                  className={styles.link}
                  onClick={() => setIsOpen(false)}
                >
                  {link.title}
                </Link>
              ))}
            </div>
            <div className={styles.currLangWrapper}>
              {currencyData ? (
                <CurrencySelector
                  options={currencyOptions}
                  value={currencyData}
                  onChange={handleChangeSelectData}
                  className={styles.currencySelector}
                  textColor={width < 1024 ? "#FCFCFC" : ""}
                  iconColor={width < 1024 ? "#FCFCFC" : ""}
                />
              ) : null}

              <LanguageSelector
                options={languages}
                value={languages.find((lang) => lang.value === locale)}
                onChange={handleChangeLanguge}
                className={styles.languageSelector}
                textColor={width < 1024 ? "#FCFCFC" : ""}
                iconColor={width < 1024 ? "#FCFCFC" : ""}
              />
            </div>
            <div className={styles.userWrapper}>
              {!isAuthenticated ? (
                <div className={styles.buttonsWrapper}>
                  <Button
                    variant={width > 1023 ? "outline" : "default"}
                    onClick={() => navigate(PATHS.login)}
                  >
                    <Trans>Log In</Trans>
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => navigate(PATHS.signup)}
                  >
                    <Trans>Sign Up</Trans>
                  </Button>
                </div>
              ) : (
                <div className={styles.user}>
                  {isAuthLoading ? (
                    <Spinner />
                  ) : userData ? (
                    <>
                      <div
                        className={styles.pictureWrapper}
                        ref={clickRef}
                        onClick={() => setShow((prev) => !prev)}
                      >
                        {userData.avatar ? (
                          <img
                            src={`data:image/png;base64, ${userData.avatar}`}
                            alt="user-avatar"
                          />
                        ) : (
                          <img src={user} alt="empty user" />
                        )}

                        <p>{truncateString(userData.username, 9)} </p>
                        <ArrowDownIcon
                          color={width > 1023 ? "#000" : "#fdfdfd"}
                          className={classnames(
                            styles.arrowIcon,
                            show ? styles.activeIcon : null
                          )}
                        />
                        {show ? (
                          <div className={styles.dropDownMenu}>
                            {linksData.map((link, index) => (
                              <Link
                                to={link.to}
                                key={index}
                                className={styles.link}
                                onClick={() => setIsOpen(false)}
                              >
                                {link.title}
                              </Link>
                            ))}
                            <Link
                              to={PATHS.index}
                              className={styles.link}
                              onClick={handleLogOut}
                            >
                              <Trans>Log Out</Trans>
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              )}

              <div
                className={styles.cartIcon}
                onClick={() => setIsOpenPreviewCart(true)}
              >
                {cartCount ? (
                  <div className={styles.cartAmount}>
                    <p>{cartCount}</p>
                  </div>
                ) : null}

                <CartIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputWrapper} ref={inputWrapperRef}>
          <Input
            variant="search"
            value={bookTitle}
            onChange={handleChangeHeaderData}
            placeholder={t(i18n)`Search`}
            onFocus={() => setIsFocusedMenu(true)}
          />

          {((filteredBooks && filteredBooks.length) ||
            (filteredCollections && filteredCollections.length)) &&
          bookTitle &&
          isFocusedMenu ? (
            <div
              className={styles.dropDownItems}
              onMouseDown={() => setIsFocusedMenu(true)}
            >
              {filteredBooks?.length ? (
                <div className={styles.itemsWrapper}>
                  <p className={styles.itemsTitle}>
                    <Trans>Books</Trans>
                  </p>
                  <div className={styles.items}>
                    {filteredBooks.map((book) => (
                      <p
                        key={book.id}
                        className={styles.itemTitle}
                        onClick={() => handleNavigateToCurrentBook(book.id)}
                      >
                        {book.title}
                      </p>
                    ))}
                  </div>
                </div>
              ) : null}

              {filteredCollections?.length ? (
                <div className={styles.itemsWrapper}>
                  <p className={styles.itemsTitle}>
                    <Trans>Collections</Trans>
                  </p>
                  <div className={styles.items}>
                    {filteredCollections.map((collection) => (
                      <p
                        key={collection.id}
                        className={styles.itemTitle}
                        onClick={() =>
                          handleNavigateToCurrentCollection(collection.id)
                        }
                      >
                        {collection.title}
                      </p>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      <PreviewCartModal
        isOpen={isOpenPreviewCart}
        onClose={() => setIsOpenPreviewCart(false)}
        handleOpenCart={handleOpenCartModal}
      />

      <CartModal
        isOpen={isOpenCartModal}
        onClose={() => setIsOpenCartModal(false)}
      />
    </header>
  );
}
