import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { openBankingContext } from "../../../context/open-banking-provider/OpenBankingProvider";

// context
export function usePaymentSuccess(orderId: string | null) {
  const { error } = useContext(errorContext);
  const { getReferenceId } = useContext(openBankingContext);

  const [isPaymentScreenLoading, setIsPaymentScreenLoading] =
    useState<boolean>(true);
  const [referenceId, setReferenceId] = useState<string>("");

  const booksAndCollectionsFetch = async () => {
    try {
      setIsPaymentScreenLoading(true);

      if (orderId) {
        const reference = await getReferenceId(orderId);

        if (reference) {
          setReferenceId(reference);
        }
      }
    } catch (err) {
      error(err);
    } finally {
      setIsPaymentScreenLoading(false);
    }
  };

  useEffect(() => {
    booksAndCollectionsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return {
    isPaymentScreenLoading,
    referenceId,
  };
}
