import React, { FunctionComponent, useMemo, useState } from "react";

// consts
import { CART } from "./CartProvider.consts";

// types
import type {
  CartContext,
  CartProduct,
  CartProviderProps,
} from "./CartProvider.types";
import { BookType } from "../books-provider/BooksProvider.types";
import { CollectionType } from "../collections-provider/CollectionsProvider.types";

export const cartContext = React.createContext({} as CartContext);

export const CartProvider: FunctionComponent<CartProviderProps> = (props) => {
  const { children } = props;

  const [cartProducts, setCartProducts] = useState<CartProduct>({
    books: [],
    collections: [],
  });
  const [cartCount, setCartCount] = useState(0);

  const getCartProducts = () => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    const amountCartItems = cart.books.length + cart.collections.length;

    setCartProducts(cart);
    setCartCount(amountCartItems);
  };

  const addBookToCart = (book: BookType) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    if (
      !cart.books.some((cartBookId) => {
        return cartBookId === book.id;
      })
    ) {
      const updatedCart = {
        ...cart,
        books: [...cart.books, book.id],
      };

      localStorage.setItem(CART, JSON.stringify(updatedCart));

      const amountCartItems =
        updatedCart.books.length + updatedCart.collections.length;

      return setCartCount(amountCartItems);
    }
  };

  const addCollectionToCart = (collection: CollectionType) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    if (
      !cart.collections.some((cartCollectionId) => {
        return cartCollectionId === collection.id;
      })
    ) {
      const updatedCart = {
        ...cart,
        collections: [...cart.collections, collection.id],
      };

      localStorage.setItem(CART, JSON.stringify(updatedCart));

      const amountCartItems =
        updatedCart.books.length + updatedCart.collections.length;

      return setCartCount(amountCartItems);
    }

    const currentCart = cart.collections.length
      ? cart
      : { ...cart, collections: [collection.id] };

    localStorage.setItem(CART, JSON.stringify(currentCart));

    const amountCartItems =
      currentCart.books.length + currentCart.collections.length;

    return setCartCount(amountCartItems);
  };

  const deleteBookFromCart = (bookId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    const filteredBooks = cart.books.filter(
      (cartBookId) => cartBookId !== bookId
    );

    const updatedCart = {
      ...cart,
      books: filteredBooks,
    };

    localStorage.setItem(CART, JSON.stringify(updatedCart));

    const amountCartItems =
      updatedCart.books.length + updatedCart.collections.length;

    return setCartCount(amountCartItems);
  };

  const deleteCollectionFromCart = (collectionId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    const filteredCollections = cart.collections.filter(
      (cartCollectionId) => cartCollectionId !== collectionId
    );

    const updatedCart = {
      ...cart,
      collections: filteredCollections,
    };

    localStorage.setItem(CART, JSON.stringify(updatedCart));

    const amountCartItems =
      updatedCart.books.length + updatedCart.collections.length;

    return setCartCount(amountCartItems);
  };

  const removeOneBook = (bookId: string) => {
    const cartString = localStorage.getItem("cart");

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { books: [], collections: [] };

    const currentIndex = cart.books.indexOf(bookId);

    if (currentIndex !== -1) {
      cart.books.splice(currentIndex, 1);
    }

    localStorage.setItem(CART, JSON.stringify(cart));

    const amountCartItems = cart.books.length + cart.collections.length;

    setCartCount(amountCartItems);
  };

  const contextValue = useMemo(
    () => ({
      cartProducts,
      cartCount,
      getCartProducts,
      addBookToCart,
      addCollectionToCart,
      deleteBookFromCart,
      deleteCollectionFromCart,
      removeOneBook,
    }),
    [
      cartProducts,
      cartCount,
      getCartProducts,
      addBookToCart,
      addCollectionToCart,
      deleteBookFromCart,
      deleteCollectionFromCart,
      removeOneBook,
    ]
  );

  return (
    <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
  );
};
